export default {
  chart: {
    height: 400,
    type: 'bar',
    zoom: {
      enabled: false,
    },
    background: '#fff',
    fontFamily: 'Montserrat',
    foreColor: '#3E4651',
  },
  colors: ['#28C76F', '#00CFE8'],
  grid: {
    show: true,
    borderColor: '#D6D8DE',
    strokeDashArray: 0,
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    padding: {
      top: 0,
      right: 20,
      bottom: 0,
      left: 10,
    },
  },
  plotOptions: {

    bar: {
      columnWidth: '20%',
      borderRadius: 5,
    },
  },
  dataLabels: {
    enabled: false,
  },

  // 'Tân Yên', 'Bà Rịa'
  xaxis: {
    categories: [],
    tickAmount: 9,
    position: 'bottom',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    tickPlacement: 'between',
    tooltip: {
      enabled: true,
    },
    labels: {
      show: true,
      rotate: 0,
      rotateAlways: false,
      showDuplicates: false,
      trim: false,
      style: {
        colors: [],
        fontSize: '12px',
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
    },
  },

  yaxis: {
    min: 0,
    tickAmount: 10,
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: true,
    },
    labels: {
      show: true,
      style: {
        fontSize: '12px',
      },
      formatter(val) {
        return `${val.toFixed(0)}`
      },
    },

  },
  tooltip: {
    enabled: true,
    intersect: false,
    x: {
      show: false,
    },
  },
  noData: {
    text: 'Không có dữ liệu',
    align: 'center',
    verticalAlign: 'middle',
    offsetY: -17,
    style: {
      color: '#8088f8',
      fontSize: '18px',
      fontFamily: 'Montserrat',
    },
  },
}

export default {

  colors: ['#227FF4', '#FF9F43'],
  legend: {
    position: 'bottom',
  },
  chart: {
    type: 'pie',
  },
  plotOptions: {
    pie: {
      donut: {
        size: '45%',
      },
    },
  },
  labels: ['nam', 'nữ'],

  dataLabels: {
    enabled: true,
    enabledOnSeries: undefined,
    formatter(val, opts) {
      return `${val.toFixed(0)}%`
    },
    textAnchor: 'middle',
    distributed: false,
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
    },
    dropShadow: {
      enabled: false,
    },
  },
}

<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Số lượng lao động theo cơ cấu tuổi, giới tính</b-card-title>
      </b-card-header>

      <!-- chart -->
      <b-card-body>
        <vue-apex-charts
          type="bar"
          :options="tempCol"
          :series="seriesCol"
          height="450px"
        />
      </b-card-body>
    </b-card>
    <b-row class="match-height">
      <b-col lg="6"><b-card
        v-if="chartDonut"
        class="card-tiny-line-stats"
        body-class="pb-50"
      >
        <h4 class="font-weight-bolder mb-1">
          Số lượng lao động theo tuổi
        </h4>
        <!-- chart -->
        <vue-apex-charts
          class="chart-gender"
          :options="chartDonut"
          :series="total"
          height="400px"
        />
      </b-card>
      </b-col>
      <b-col lg="6">
        <b-card
          class="card-tiny-line-stats"
        >
          <h4 class="font-weight-bolder mb-1">
            Số lượng lao động theo giới tính
          </h4>
          <!-- chart -->
          <vue-apex-charts
            class="chart-gender"
            height="400px"
            :options="chartPercent"
            :series="seriesPercent"
          />
        </b-card>
      </b-col>
    </b-row>
    <div class="page-container-table">

      <!--Phần header của bảng -->
      <button-all-header
        :arrayExcel="[]"
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        class="custtom-header pr-1"
        @clickExportExcel="dowloadFile"
        @search="search($event)"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
      />

    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BCardHeader,
  BCardBody,
  BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import chartOptionsDonut from '../configChart/ChartPercentDonut'
import ChartPercent from '../configChart/ChartPercent'
import ColomChart from '../configChart/ColumChart'

import store from '../../store'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstanstApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BCard,
    VueApexCharts,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  data() {
    return {
      dataPercent: {},
      dataPercentCompany: {
        series: [1500, 600],
      },
      data: {
        series: [10, 20, 30, 40, 40, 60, 70, 80],
      },
      dataList: [],
      columns: [
        {
          label: 'NHÓM TUỔI',
          field: 'name',
        },
        {
          label: 'NAM',
          field: 'male',
        },
        {
          label: 'NỮ',
          field: 'female',
        },
      ],
      urlQuery: {
        search: '',
      },
      totalRecord: 0,
      currentPage: 10,
      seriesCol: [],
      seriesDonut: [],
      seriesPercent: [],
      male: [],
      female: [],
      chartOptionsCol: ColomChart,
      chartDonut: chartOptionsDonut,
      chartPercent: ChartPercent,
      tempCol: {},
      tempDonut: {},
      age: [],
      total: [],
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {

    async dowloadFile() {
      const name = 'ToanTinhTheoCoCauTuoiGioiTinh.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL)
    },
    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const res = await axiosApiInstance.get(ConstantsApi.API_TABLE_AGE_GENDER, {
        params: urlQuery,
      })
      const sum = (previousValue, currentValue) => previousValue + currentValue
      this.dataList = res?.data?.data
      this.$hideLoading()

      // Nam theo độ tuổi
      this.male = this.dataList.map(e => e.male)

      // Nữ theo độ tuổi
      this.female = this.dataList.map(e => e.female)

      // Tổng nam , tổng nữ
      this.seriesPercent = [this.male.reduce(sum), this.female.reduce(sum)]

      // Tổng nam nữ theo độ tuổi
      this.total = this.dataList.map(e => e.total)

      // Dữ liệu độ tuổi
      this.age = this.dataList.map(e => e.name)

      // Dữ liệu độ tuổi cho biểu đồ tròn
      this.chartDonut.labels = this.age
      this.tempDonut = this.chartDonut

      // Dữ liệu độ tuổi của biểu đồ cột
      this.chartOptionsCol.xaxis.categories = this.age
      this.tempCol = {}
      this.$nextTick(() => {
        this.tempCol = this.chartOptionsCol
      })

      // Dữ liệu biểu đồ cột của nam và nữ
      this.seriesCol = [
        {
          name: 'Nam',
          data: this.male,
        },
        {
          name: 'Nữ',
          data: this.female,
        },
      ]
    },

    // Lấy danh sách tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchData(this.urlQuery)
      }
    },
  },

}
</script>

<style lang="scss">
  .chart-gender{
    display: flex;
    justify-content: center;
  }
</style>
